<template>
  <div class="upload">
    <div class="list">
      <p class="imgBox" v-for="(item, index) in imgData" :key="index">
        <img :src="item" alt="" />
        <!-- <span @click="del(index)"><img src="./img/delete.png" alt=""></span> -->
      </p>
      <p class="imgBox" v-for="(item, index) in imgSupplement" :key="index">
        <img :src="item" alt="" />
        <span @click="del(index)"><img src="./img/delete.png" alt="" /></span>
      </p>
      <!-- <van-uploader
        v-model="fileList"
        :preview-full-image="true"
        :after-read="afterRead"
        :preview-image="false"
        :multiple="true"
        accept="image/*"
      >
        <van-button icon="plus"></van-button>
      </van-uploader> -->
      <div class="uploadImg" @click="selectImg">
        <img src="./img/upload.png" alt="" />
      </div>
    </div>

    <div class="submit" @click="submit">提交</div>
  </div>
</template>

<script>
import { toRefs, reactive, onMounted } from "vue";
import { useRoute } from "vue-router";
import { Toast } from "vant";
import APP from "@/utils/App";
import axios from "axios";
import API from "../../server/api";
import { dealImage } from "../../utils/index";

export default {
  setup() {
    const route = useRoute();
    const state = reactive({
      fileList: [],
      fileName: "",
      fileType: "",
      num: 0,
      uploadName: "",
      uploadType: "",
      creditNo: "",
      userId: "",
      imgList: [],
      imgSupplement: [],
      imgData: [], // 图片路径
    });
    //选择照片
    const selectImg = () => {
      if (!APP.BROWSER.android) {
        //ios上传方式
        APP.iOS_PICKPHOTO(async (res) => {
          console.log(res, "ios选择照片");
          if (res.code == 0) {
            if (res.response.images) {
              let imgArr = [] 
              for (var i = 0; i < res.response.images.length; i++) {
                let response = await dealImage(
                  "data:image/jpg;base64," + res.response.images[i],
                  800,
                );
                imgArr.push(response);
              }
              uploadOSS(imgArr);
            }
          

          }
        });
      } else {
        APP.PHOTO_CAMERA_MORE(async(res) => {
          console.log(res, "00000");
          if (res.code == 0) {
            if (res.response.images) {
              let imgArrs = [];
              for (var i = 0; i < res.response.images.length; i++) {
                let responses = await dealImage(
                  "data:image/jpg;base64," + res.response.images[i],
                  800,
                );
                imgArrs.push(responses);
              }
              uploadOSS(imgArrs);
            }
          }
        });
      }
    };
    //使用压缩
    // const printing = (base64) => {
    //   const imgArr = [];
    //   imgArr.push(base64);
    //   uploadOSS(imgArr);
    //   console.log("压缩后", base64.length / 1024);
    // };
    // 上传到oss
    const uploadOSS = async (file) => {
      // console.log(file, "996554");
      for (var i = 0; i < file.length; i++) {
        const fileKey = "LRD-FILE";
        const result = await API.uploadOss({
          fileName: "jpg",
          fileType: fileKey,
        });
        if (result.code == 200) {
          const { host, accessId, dir, policy, signature } = result.result;
          const data = new FormData();
          data.append("key", dir);
          data.append("policy", policy);
          data.append("OSSAccessKeyId", accessId);
          data.append("success_action_status", "200");
          data.append("Signature", signature);
          data.append("file", base64ToFile(file[i]));
          const baseUrl = host;
          Toast.loading({
            message: "加载中...",
            forbidClick: true,
          });
          /* 上传到OSS */
          axios({
            url: baseUrl,
            method: "post",
            data: data,
            headers: { "Content-Type": "multipart/form-data" },
          })
            .then((res) => {
              if (res.status === 200) {
                const imgUrl = `${baseUrl}${dir}`;
                console.log(imgUrl, "图片地址");
                state.imgSupplement.push(imgUrl);
              }
            })
            .catch((e) => {
              Toast.fail("图片上传失败，请刷新页面重试");
            });
        }
      }
    };
    // base64 转图片的方法
    const base64ToFile = (dataUrl, name) => {
      const arr = dataUrl.split(",");
      // eslint-disable-next-line no-unused-vars
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], name + ".jpg", { type: "image/jpg" });
    };
    // const afterRead = (file) => {
    //   // console.log(file, "000");
    //   const imgarr = [];
    //   if (!Array.isArray(file)) {
    //     uploadOSS(file.content);
    //   } else {
    //     for (var key in file) {
    //       imgarr.push(file[key].content);
    //     }
    //     uploadOSS(imgarr);
    //   }
    // };
    const uploadFile = async (data) => {
      const imgFile = await API.uploadFile(data);
      if (imgFile) {
        Toast.success("上传成功");
        setTimeout(() => {
          APP.BACK(-1);
        }, 1000);
      }
    };
    // 获取图片列表
    const getPhotoList = async () => {
      const photos = await API.getPhotoList({
        creditNo: state.creditNo,
        type: state.uploadType,
      });
      state.imgData = photos.imgData;
      state.imgSupplement = photos.imgSupplement;
    };
    onMounted(async () => {
      const routeInfo = route.query.jsParam;
      const arr = routeInfo.split("&");
      const obj = {};
      for (let i = 0; i < arr.length; i++) {
        const aa = arr[i].split("=");
        obj[aa[0]] = aa[1];
      }
      state.routeInfo = obj;
      console.log(state.routeInfo, "route");
      state.uploadName = state.routeInfo.everyName;
      state.uploadType = state.routeInfo.type;
      state.creditNo = localStorage.getItem("creditNo");
      state.userId = localStorage.getItem("userId");
      APP.SET_TITLE("上传截图");

      console.log("补充资料");
      await getPhotoList();
    });
    // 删除上传的图片
    const del = (n) => {
      state.imgSupplement.splice(n, 1);
    };

    // 点击提交按钮上传图片
    const submit = () => {
      const imgModel = {};
      imgModel.imgSupplement = state.imgSupplement;
      imgModel.type = state.uploadType;
      imgModel.imgData = [];
      uploadFile({
        creditNo: state.creditNo,
        imgModel: imgModel,
        userId: state.userId,
      });
    };

    return {
      ...toRefs(state),
      // afterRead,
      submit,
      del,
      selectImg,
    };
  },
};
</script>

<style lang="less" scoped>
.upload {
  width: 100%;
  height: 100vh;
  // background-color: #f7f6f9;
  // padding: 20px 53px 0 56px;
}
.upload .list {
  width: 100%;
  // height: 100%;
  display: flex;
  padding-left: 56px;
  box-sizing: border-box;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.uploadImg {
  margin-top: 22px;
}
.uploadImg img {
  width: 200px;
  height: 200px;
}
.van-button {
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: #eeeeee;
}

.van-button__content {
  width: 100px;
  height: 100px;
}

.submit {
  width: 394px;
  height: 80px;
  background: linear-gradient(180deg, #d0badf 0%, #7370f6 100%);
  border-radius: 42px;
  font-size: 32px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 80px;
  text-align: center;
  margin: 300px auto;
}

.imgBox {
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: flex-start;
  position: relative;
  margin-right: 20px;
  margin-bottom: 10px;
}

.imgBox img {
  width: 100%;
  height: 100%;
  /* margin-right: 20px; */
  margin-bottom: 10px;
}

.imgBox span {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
}

.imgBox span img {
  width: 28px;
  height: 28px;
}
</style>

